<template>
    <div>
        <!-- 顶部工具条 -->
        <el-col :span="24">
            <el-form :inline="true">
                <!-- <el-form-item label='市：'>
                    <el-select v-model="filters.cityID" clearable  @change='selCity' ref ='cityRef' placeholder="请选择">
                        <el-option
                            v-for="item in cityIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='区/县：'>
                    <el-select v-model="filters.regionID" ref ='regionRef' clearable placeholder="请选择">
                        <el-option
                            v-for="item in regionIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="地区:">
                    <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                </el-form-item> -->
                <el-form-item label='关键字:'>
                    <el-input placeholder="姓名/手机号" 
                        v-model="filters.searchKey"
                        clearable
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label='单位名称:'>
                    <el-input placeholder="请输入单位名称" 
                        v-model="filters.unitName"
                        clearable
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label='信用代码:'>
                    <el-input placeholder="请输入信用代码" 
                        v-model="filters.creditCode"
                        clearable
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="岗位:">
                    <el-select v-model="filters.station" placeholder="请选择岗位" clearable>
                        <el-option 
                            v-for="item in stationArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行业:">
                    <el-select v-model="filters.trade" placeholder="请选择行业" clearable>
                        <el-option 
                            v-for="item in tradeArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="通知次数:">
                    <el-select v-model="filters.notification" placeholder="请选择通知次数" clearable>
                        <el-option 
                            v-for="item in notificationArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="filters.status" placeholder="请选择状态" clearable>
                        <el-option 
                            v-for="item in statusArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格 -->
        <el-table 
            v-loading="tableLoading"
            :data="tableData" 
            style="width:100%" 
            border 
            ref="multipleTable"
            highlight-current-row
            @current-change="handlerowChange"
            @selection-change="handleSelectChange"
        >
            <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
            <!-- <el-table-column type="selection" width="45"></el-table-column> -->
            <el-table-column  label="市" width="" align="center" prop="City"></el-table-column>
            <el-table-column  label="区" width="" align="center" prop="Region"></el-table-column>
            <el-table-column  label="单位名称" min-width="100" align="center" prop="UnitName"></el-table-column>
            <el-table-column  label="行业（场所）" min-width="120" align="center" prop="TradeName"></el-table-column>
            <el-table-column  label="岗位（人群）" min-width="120" align="center" prop="StationName"></el-table-column>
            <el-table-column label="姓名" width="" align="center" prop="Name"></el-table-column>
            <el-table-column label="手机号" width="" align="center" prop="PhoneNumber"></el-table-column>
            <el-table-column label="创建时间" min-width="100" align="center" :formatter="formatCreateTime" prop="NoticeTime"></el-table-column>
            <!-- <el-table-column label="最后通知时间" min-width="120" align="center" :formatter="formatCreateTime" prop="NoticeTime"></el-table-column> -->
            <el-table-column label="通知次数" min-width="100" align="center" prop="NoticeCount"></el-table-column>
            <el-table-column  label="状态" width="" align="center" prop="RequestSource">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.Status == 1400">已注册</el-tag>
                    <el-tag v-else-if="scope.row.Status == 1500">已报考</el-tag>
                    <el-tag v-else-if="scope.row.Status == 1600">已报考</el-tag>
                    <el-tag v-else-if="scope.row.Status == 1700">已取证</el-tag>
                    <el-tag v-else>未注册</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <!-- <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div> -->
            <div>
                <el-pagination
                    small
                    :page-sizes="pages.pageArr"
                    :page-size="pages.pageSize"
                    :current-page="pages.pageIndex"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.dataCount"
                    @size-change="handleSizeChange" 
                    @current-change="handleCurrentChange"
                    class="pageBar"       
                >
                </el-pagination>
            </div>
        </el-col>
        <!-- 创建人群 -->
        <!-- <el-col :span="24" class="pageBar">
            <div class="groupClass">
                <el-form :inline='true' :model='addSocialGroupFrom' :rules='addSocialGroupRules' ref='addSocialGroupRef'>
                    <el-form-item label="查询出人员数量:">
                        {{pages.dataCount}}
                    </el-form-item>
                    <el-form-item label="">
                    </el-form-item>
                    <el-form-item label="">
                    </el-form-item>
                    <el-form-item label="">
                        <span style="color: #409EFF">
                            {{addSocialGroupFrom.viewName}}
                        </span>
                    </el-form-item>
                    <el-form-item label="人群名称:" prop="groupName">
                        <el-input v-model='addSocialGroupFrom.groupName' placeholder="输入人群名称" maxlength="10" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click.native="addSocialGroupSubmit" :loading="addSocialGroupLoading">创建人群</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-col> -->
        <!--新增/编辑界面-->
        <el-dialog
            :title="addOrEdi?'新增':'编辑'"
            :visible.sync="addFormVisibleDialog"
            v-model="addFormVisibleDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="formData" label-width="150px" :rules="formDataRules" ref="formData">
                <el-form-item label="单位名称:" prop="socialUnit">
                    <el-select
                        v-model="formData.socialUnit"
                        filterable
                        remote
                        clearable
                        reserve-keyword
                        placeholder="请输入单位名称"
                        :remote-method="unitChange"
                        :loading="unitLoading"
                        style="width: 300px;"
                    >
                        <el-option
                            v-for="item in socialUnitArr"
                            :key="item.ID"
                            :label="item.UnitName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="统一社会信用代码证:">
                    {{formData.socialUnit ? formData.socialUnit.SocialCreditNumber : ''}}
                </el-form-item>
                <el-form-item label="单位行业（场所）:">
                    {{formData.TradeName ? formData.socialUnit.TradeName : ''}}
                </el-form-item>
                <div v-for="(item,index) in formData.stationInfoArr" :key="index">
                    <el-form-item label="岗位:" prop="stationId">
                        <el-select v-model="item.stationId" placeholder="请选择岗位" clearable style="width: 300px;">
                            <el-option 
                                v-for="item in stationArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                        <el-button v-if="index == 0" @click="addStationInfo" type="text" style="margin-left: 20px;">添加</el-button>
                        <el-button v-if="index != 0" @click="delStationInfo(index)" type="text" style="margin-left: 20px;color: #f56c6c;">删除</el-button>
                    </el-form-item>
                    <el-form-item label='姓名:' prop="stationId">
                        <el-input placeholder="请输入姓名" v-model="item.infoName" style="width: 300px;" clearable></el-input>
                    </el-form-item>
                    <el-form-item label='手机号:' prop="stationId">
                        <el-input placeholder="请输入手机号" v-model="item.infoPhone" style="width: 300px;" clearable></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisibleDialog = false">取消</el-button>
                <el-button type="primary" @click.native="submitFormData" :loading="submitFormDataLoading">确定</el-button>
            </div>
        </el-dialog>
        <!--应用情况抽查-->
        <el-dialog
            title="应用情况抽查"
            :visible.sync="randomCheckDialog"
            v-model="randomCheckDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="randomCheckForm" label-width="130px" :rules="randomCheckFormRules" ref="randomCheckFormRef">
                <el-form-item label="第一步:" prop="socialUnit">上传抽查模板</el-form-item>
                <el-form-item label="">
                    <el-upload
                        class="upload-demo"
                        drag
                        method="GET"
                        :action="importStudentUrl"
                        :headers="headers"
                        :http-request="uploadRequest"
                        :before-upload="beforeUpload"
                        :on-success="handleSuccess"
                        :on-error='handleError'
                        multiple
                        ref="upload"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                    <span>只能上传xlsx文件，且不超过500kb</span>
                </el-form-item>
                <el-form-item label="第二步:">下载结果文件</el-form-item>
                <el-form-item label="">
                    <el-button @click="downloadTemplate" :disabled="isAgreeDownStatus" type="primary">下载excel文件</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="randomCheckDialog = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import util from "../../../util/date";
import Qs from 'qs' 
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
// 数据请求
import { getSocialUnitStaffPageList,saveSocialUnitStaff,deleteSocialUnitStaff,getDataDictionaryList,getAreaDictionaryList,addSocialGroup,getSocialUnitListPage,uploadProcessAndReturnExcel,processAndReturnExcel } from '@/api/api'
import { validPhone } from "../../../util/validate";
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                searchKey:'',
                unitName:'',
                creditCode:'',
                provinceID: 1919,
                cityID: '',
                regionID: '',
                selecAreaArr: [],
                station: '',
                trade: '',
                notification: '',
                status: "",
            },
            cityIDArr: [],
            regionIDArr: [],
            stationArr: [], //岗位
            tradeArr: [], //行业
            // 通知次数
            notificationArr: [
                {name: "0",value: 0},
                {name: "1",value: 1},
                {name: "2",value: 2},
                {name: "3",value: 3},
                {name: "4",value: 4},
                {name: "5",value: 5},
                {name: "6",value: 6},
                {name: "7",value: 7},
                {name: "8",value: 8},
                {name: "9",value: 9},
                {name: "10",value: 10},
            ],
            // 状态
            statusArr: [
                {name: "未注册",value: 0},
                {name: "已注册",value: 1400},
                {name: "已报考",value: 1500},
                // {name: "已学习",value: 1600},
                {name: "已取证",value: 1700},
            ],
            // 批次分页
            brandListPage: {
                DataCount: 10,
                pageIndex: 1,
                pageSize: 10,
            },
            // 表格
            tableData: [],
            tableLoading: false,
            allSelect: '',
            currentRow: '',
            // 分页
            pages:{
                pageArr:[10,20,30,40],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 创建人群
            addSocialGroupLoading: false,
            addSocialGroupFrom: {
                viewName: '',
                groupName: '',
            },
            addSocialGroupRules: {
                groupName:[{required:true,message:'请输入人群名称',trigger:'blur'}],
            },
            // 新增/编辑
            addOrEdi: true,
            addFormVisibleDialog: false,
            submitFormDataLoading: false,
            formData: {
                socialUnit: '',
                stationInfoArr: [
                    {stationId: "",infoName: "",infoPhone: ""},
                ],
                stationId: 1,
            },
            formDataRules: {
                socialUnit: [{required: true, message: "请输入单位名称", trigger: "blur" }],
                stationId: [{required: true, message: "请选择岗位", trigger: "blur" }],
            },
            unitLoading: false,
            socialUnitArr: [],
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response.reverse()
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
            ProvinceID: 1919,
            CityID: '',
            RegionID: '',
            TownID: '',
            isLevel: '',
            // 应用情况抽查
            randomCheckDialog: false,
            isAgreeDownStatus: true,
            importStudentUrl:'',
            fileStreamData: '',
            randomCheckForm: {

            },
            randomCheckFormRules: {

            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getSocialUnitStaffPageList() {
            this.getTableDataFun();
            var provinceText = "广东省"
            var cityText = this.$refs.cityRef.selected.label
            var regionText = this.$refs.regionRef.selected.label
            if(provinceText && provinceText != 'undefined') {
                this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText
                if(cityText && cityText != 'undefined') {
                    this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText
                    if(regionText && regionText != 'undefined') {
                        this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText + regionText
                    }
                }
            } else {
                this.addSocialGroupFrom.viewName = ''
            }
        },
        // 列表
        getTableDataFun(e) {
            var params = {
                keyWord: this.filters.searchKey,
                unitName: this.filters.unitName,
                socialCreditNumber: this.filters.creditCode,
                provinceID: this.filters.provinceID,
                cityID: this.filters.cityID,
                regionID: this.filters.regionID,
                stationID: this.filters.station,
                tradeID: this.filters.trade,
                noticeCount: this.filters.notification,
                status: this.filters.status,
                provinceID: this.ProvinceID,
                cityID: this.CityID,
                regionID: this.RegionID,
                townID: this.TownID,
                isLevel: this.isLevel ? this.isLevel : "",
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            // if(this.filters.selecAreaArr.length > 0) {
            //     params.cityID = this.filters.selecAreaArr[0]
            // }
            // if(this.filters.selecAreaArr.length > 1) {
            //     params.regionID = this.filters.selecAreaArr[1]
            // }
            this.tableLoading = true
            getSocialUnitStaffPageList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading = false
                    this.tableData = result.Response.Data
                    this.pages.dataCount = result.Response.DataCount
                    if(e == 1) {
                        this.$confirm(`确定将【` + this.pages.dataCount + `】单位数量创建人群？`,"提示",{
                        }).then(() => {
                            this.addSocialGroupLoading = true
                            var params = {
                                name: this.addSocialGroupFrom.viewName,
                                customName: this.addSocialGroupFrom.groupName,
                                groupType: 2,
                            }
                            // if(this.filters.selecSheng) {
                            // params.conditionJson = JSON.stringify({provinceID: this.filters.selecSheng})
                            params.conditionJson = JSON.stringify({provinceID: 1919})
                            if(this.filters.cityID) {
                                    params.conditionJson = JSON.stringify({provinceID: 1919,cityID: this.filters.cityID})
                                    if(this.filters.regionID) {
                                        params.conditionJson = JSON.stringify({provinceID: 1919, cityID: this.filters.cityID, regionID: this.filters.regionID})
                                    }
                                // }
                            }
                            // else {
                            //     params.conditionJson = null
                            // }
                            addSocialGroup(params).then(res => {
                                this.addSocialGroupLoading = false
                                var result = res.data
                                if(result.Success) {
                                    this.$message.success(result.Message)
                                } else {
                                    this.$message.error(result.Message)
                                }
                            })
                        })
                        .catch(() => {
                            
                        })
                    }
                }
            })
        },
        // 新增
        handleAdd() {
            this.addOrEdi = true
            this.addFormVisibleDialog = true
            this.formData = {
                socialUnit: '',
                stationInfoArr: [
                    {stationId: "",infoName: "",infoPhone: ""},
                ],
                stationId: 1,
            }
        },
        // 编辑
        handleEdit() {
            if(this.currentRow) {
                this.addOrEdi = false
                this.addFormVisibleDialog = true
                this.formData = {
                    socialUnit: this.currentRow.UnitName,
                    stationInfoArr: [
                        {
                            stationId: this.currentRow.StationID,
                            infoName: this.currentRow.Name,
                            infoPhone: this.currentRow.PhoneNumber
                        },
                    ],
                    stationId: 1,
                }
                if(this.currentRow.UnitName) {
                    this.unitChange(this.currentRow.UnitName,true)
                }
            } else {
                this.$message.warning("请先选择要编辑的数据！")
            }
        },
        // 新增/编辑提交
        submitFormData() {
            this.$refs.formData.validate(valid => {
                if (valid) {
                    var newArr = []
                    this.formData.stationInfoArr.forEach(item => {
                        var newObj = {}
                        if(!item.stationId) {
                            this.$message.warning("请选择岗位！")
                            return
                        }
                        if(!item.infoName) {
                            this.$message.warning("请输入姓名！")
                            return
                        }
                        if(!item.infoPhone) {
                            this.$message.warning("请输入手机号！")
                            return
                        }
                        if (!validPhone(item.infoPhone)) {
                            this.$message.warning("手机号" + item.infoPhone + "输入有误！")
                            return
                        }
                        newObj.Name = item.infoName
                        newObj.PhoneNumber = item.infoPhone
                        newObj.StationID = item.stationId
                        newObj.SocialUnitID = this.formData.socialUnit.ID
                        newObj.PastUnitID = this.currentRow.SocialUnitID == this.formData.socialUnitItem.ID ? null : this.currentRow.SocialUnitID
                        if(!this.addOrEdi) {
                            newObj.ID = this.currentRow.ID
                        }
                        newArr.push(newObj)
                    })
                    this.submitFormDataLoading = this.tradeArr
                    if(newArr.length > 0) {
                        saveSocialUnitStaff(newArr).then((res) => {
                            var result = res.data
                            if(result.Success) {
                                this.$message.success(result.Message)
                                this.addFormVisibleDialog = false
                                this.getTableDataFun();
                            } else {
                                this.$message.error(result.Message)
                            }
                            this.submitFormDataLoading = false
                        })
                    }
                }
            })
        },
        // 删除
        handleDel() {
            if(this.currentRow) {
                this.$confirm(`确定删除数据吗？`,"提示",{
                }).then(() => {
                    var params = {
                        staffId: this.currentRow.ID
                    }
                    deleteSocialUnitStaff(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getTableDataFun();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
            } else {
                this.$message.warning("请先选择要删除的数据！")
            }
        },
        // 创建人群
        addSocialGroupSubmit() {
            this.$refs['addSocialGroupRef'].validate(valid => {
                if(valid){
                    var isAddGroun = 1
                    this.getEnterpriseRegisterCertificateListPage(isAddGroun)
                }
            })
        },
        // 查询
        getEnterpriseRegisterCertificateListPage(e) {
            this.pages.pageIndex = 1
            this.getTableDataFun(e);
            // var provinceText = this.$refs.provinceRef.selected.label
            var provinceText = "广东省"
            var cityText = this.$refs.cityRef.selected.label
            var regionText = this.$refs.regionRef.selected.label
            if(provinceText && provinceText != 'undefined') {
                this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText
                if(cityText && cityText != 'undefined') {
                    this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText
                    if(regionText && regionText != 'undefined') {
                        this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText + regionText
                    }
                }
            } else {
                this.addSocialGroupFrom.viewName = ''
            }
        },
        // 输入单位名称获取单位信息
        unitChange(val,status) {
            if (val !== '') {
                this.unitLoading = true;
                var params = {
                    pageIndex: 1,
                    pageSize: 20,
                    socialUnitName: val
                }
                getSocialUnitListPage(params).then(res => {
                    var result = res.data
                    if(result.Success) {
                        this.socialUnitArr = result.Response.Data
                        if(status) {
                            this.formData.socialUnit = result.Response.Data[0]
                        }
                    }
                    this.unitLoading = false;
                })
            } else {
                this.socialUnitArr = [];
            }
        },
        // 应用情况抽查
        randomCheckFun() {
            this.randomCheckDialog = true
            this.isAgreeDownStatus = true
        },
        // 文件上传之前
        beforeUpload(file){
            var isLt10M = file.size / 1024 / 1024 < 0.6;
            if(!isLt10M){
                this.$message.error('上传文件大小不能超过 500KB!');
                return false
            }
            var arr = ['xlsx','xls','excel','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
            if(arr.includes(file.type) == false){
                this.$message.error('只能上传excel格式文件!');
                return false
            }
            const formData = new FormData();
            formData.append('file', file);
            return axios.post(this.importStudentUrl, formData, {
                responseType: 'arraybuffer',
            }).then(response => {
                this.$message.success('上传成功，请点击下载excel文件');
                this.fileStreamData = response.data
                this.isAgreeDownStatus = false
            }).catch(error => {
                this.$message.error('上传失败');
            });
        },
        // 上传请求
        uploadRequest() {
            console.log("上传请求")
        },
        // 文件上传成功
        handleSuccess(res,file,fileList){
            // this.fileStreamData = res
            this.isAgreeDownStatus = false
        },
        // 文件上传失败
        handleError(err,file,fileList){
            this.$message.error("文件上传失败！")
        },
        // 下载excel文件
        downloadTemplate() {
            var that = this
            that.$confirm('即将下载excel文件？',"提示",{
            }).then(() => {
                const aLink = document.createElement("a");
                let blob = new Blob([that.fileStreamData], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
                console.log("blob",blob)
                aLink.href = URL.createObjectURL(blob)
                aLink.setAttribute('download', '培训系统应用情况抽查' + '.xlsx')
                aLink.click()
                // var params = {}
                // processAndReturnExcel(params).then(res => {
                //     var result = res.data
                    // if(result.Success) {
                    //     window.open(result.Response)
                    // } else {
                    //     this.$message.error(result.Message)
                    // }
                // })
            }).catch(() => {
                
            })
        },
        // 添加岗位信息
        addStationInfo() {
            var newObj = {stationId: "",infoName: "",infoPhone: ""}
            this.formData.stationInfoArr.push(newObj)
        },
        // 删除岗位信息
        delStationInfo(val) {
            this.formData.stationInfoArr.splice(val,1)
        },
        // 分页
        handleSizeChange(newSize){
            this.pages.pageSize = newSize   
            this.getTableDataFun()
        },
        handleCurrentChange(newPage){
            this.pages.pageIndex = newPage      
            this.getTableDataFun()
        },
        // 表格当前行改变事件
        handlerowChange(currentRow){
            this.currentRow = currentRow
        },
        // 选中表格行
        handleSelectChange(allRow){
            this.allSelect = allRow
        },
        // 获取岗位/行业
        getDataDictionaryList() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.tradeArr.push(item)
                        } else if(item.DataType == 1) {
                            this.stationArr.push(item)
                        }
                    });
                }
            })
        },
        // 选中省、市、区当前行
        selProvince(value){
            this.filters.cityID = ''
            this.filters.regionID = ''
            this.parentProvinceID = value
            var params = {
                level:1,
                parentId:this.parentProvinceID
            }
            this.getCity(params)
        },
        selCity(value){
            this.parentCityID = value
            var params = {
                parentId:this.parentCityID
            }
            this.getArea(params)
        },
        getCity(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.cityIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getArea(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.regionIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        currentChange(val) {
            this.brandListPage.pageIndex = val
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        // 时间格式化
        formatCreateTime:function(row,column){
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
        getTimerFun() {
            var d = new Date();
            var curr_date = d.getDate();
            var curr_month = d.getMonth() + 1; 
            var curr_year = d.getFullYear();
            String(curr_month).length < 2 ? (curr_month = "0" + curr_month): curr_month;
            String(curr_date).length < 2 ? (curr_date = "0" + curr_date): curr_date;
            var yyyyMMdd = curr_year + "" + curr_month +""+ curr_date;
            return yyyyMMdd;
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    created(){
        this.ProvinceID = this.$route.query.ProvinceID
        this.CityID = this.$route.query.CityID
        this.RegionID = this.$route.query.RegionID
        this.TownID = this.$route.query.TownID
        this.isLevel = this.$route.query.isLevel
        this.importStudentUrl = uploadProcessAndReturnExcel
        this.getTableDataFun();
        this.getDataDictionaryList();
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getCity(params)
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>